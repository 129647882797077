require('./bootstrap');

 import Vue from 'vue';

window.Vue = Vue;

Vue.component('my-calendar', require('./components/MyCalendar.vue').default);

const app = new Vue({
    el: '#app',
});
