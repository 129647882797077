<template>
    <FullCalendar :options="calendarOptions"/>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import deLocale from '@fullcalendar/core/locales/de-at';

export default {
    components: {
        FullCalendar
    },
    data() {
        return {
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin, listPlugin],
                initialView: 'listMonth',
                events: '/events',
                locale: deLocale,
                listDaySideFormat: false,
                listDayFormat: {
                    month: 'long',
                },
                headerToolbar: {
                    start: 'title', // will normally be on the left. if RTL, will be on the right
                    center: '',
                    end: 'today prev,next listMonth,listYear' // will normally be on the right. if RTL, will be on the left
                },
                buttonText: {
                    today: 'Heute',
                    listMonth: 'Monatsüberblick',
                    listYear: 'Jahresüberblick'
                },

            },
        }
    }
}
</script>
